import { configure } from "deso-protocol";
import { useContext, useEffect, useState } from "react";
import { DeSoIdentityContext } from "react-deso-protocol";
import UserPrefsStore from 'context/userPrefsStore';
import { Loader2 } from 'lucide-react';
import { Outlet } from "react-router-dom";
import { Nav } from "../components/nav";

try {
  configure({
    spendingLimitOptions: {
      GlobalDESOLimit: 3000000000, // 1.00 DESO
      TransactionCountLimitMap: {
        BASIC_TRANSFER: "UNLIMITED",
      },
    },
  });
  console.log("DeSo configuration succeeded.");
} catch (error) {
  console.error("Failed to configure DeSo:", error);
}


export const Root = () => {
  const { currentUser, isLoading } = useContext(DeSoIdentityContext);
  const [ isUserLoading, setIsUserLoading ] = useState(true);
  const { userPrefs, setUserPrefs } = useContext(UserPrefsStore);
  const [loading, setLoading] = useState(false);

  
  

  return (
  <>
    <Nav />
    <div role="main" className="main-content">
      <div className={`main-content ${!loading ? 'loaded' : ''}`}>
        {loading ? (
          <div className="flex justify-center items-center">
            <Loader2 className="mr-2 h-10 w-10 animate-spin" />
            <div className="4xl">Loading...</div>
          </div>
        ) : (
          <Outlet />
        )}
      </div>
    </div>
  </>
);
  
};
