// userPrefsStore.js

import React, { createContext, useState, useMemo } from 'react';

const UserPrefsContext = createContext();

export const UserPrefsProvider = ({ children }) => {
  const [userPrefs, setUserPrefs] = useState(null);

  const value = useMemo(() => ({ userPrefs, setUserPrefs }), [userPrefs]);

  return (
    <UserPrefsContext.Provider value={value}>
      {children}
    </UserPrefsContext.Provider>
  );
};

export default UserPrefsContext;
