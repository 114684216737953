import { DeSoIdentityProvider } from "react-deso-protocol";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import './tailwind.output.css';
import { Home } from "./routes/home";
import { Root } from "./routes/root";
import { SignAndSubmitTx } from "./routes/sign-and-submit-tx";
import { SwitchAccount } from "./routes/switch-account";
import { GetKeys } from "./routes/getkeys"
import { User } from "./routes/user";
import React, { useState, useMemo } from "react";
import userPrefsStore from 'context/userPrefsStore';

// Create a custom component to wrap routes with context provider
function RootWithContext() {
  const [userPrefs, setUserPrefs] = useState(null);
  const userPrefsValue = useMemo(() => ({ userPrefs, setUserPrefs }), [userPrefs]);

  return (
    <userPrefsStore.Provider value={userPrefsValue}>
      <Root />
    </userPrefsStore.Provider>
  );
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootWithContext />,
    children: [
      { path: "/", element: <Home /> },
      {
        path: "/how-to-get-your-twitter-api-keys",
        element: <GetKeys />,
      },
    ],
  },
]);

function App() {
  const [userPrefs, setUserPrefs] = useState(null);

  return (
    <React.StrictMode>
      <DeSoIdentityProvider>
        <RouterProvider router={router} />
      </DeSoIdentityProvider>
    </React.StrictMode>
  );
};

ReactDOM.createRoot(document.getElementById("root")).render(<App />);